import React from "react";
import "./style.css";

const Maintenance = () => {
  // const [countdown, setCountdown] = useState("00:00:00");

  return (
    <div className="maintenance-container">
      <div className="content">
        {/* <img
          src="https://example.com/maintenance-icon.png"
          alt="Under Maintenance Icon"
          className="icon"
        /> */}
        <h1>It'll be back soon!</h1>
        <p>
          Currently performing some maintenance. I apologize for the
          inconvenience. Please check back later.
        </p>
        <div className="timer">
          <p>
            {/* Estimated time remaining: <span id="countdown">{countdown}</span> */}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
