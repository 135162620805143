import one from "../assets/svg/projects/one.svg";
import two from "../assets/svg/projects/two.svg";
import three from "../assets/svg/projects/three.svg";
import four from "../assets/svg/projects/four.svg";
import gesog from "../assets/svg/projects/italian-army.jpg";
import six from "../assets/svg/projects/six.svg";
import jewelryProject from "../assets/png/projects/jewelryProject.png";
import tuteeline from "../assets/png/projects/tuteeline.png";
import vdalavora from "../assets/png/projects/vdalavora.png";
import variety from "../assets/png/projects/variety.png";
import inbellezza from "../assets/png/projects/inbellezza.png";
import sportsKendra from "../assets/png/projects/sportsKendra.png";
import eyeSome from "../assets/png/projects/eye-some.png";
import rstore from "../assets/png/projects/rstore.png";

export const projectsData = [
  {
    id: 1,
    projectName: "VDALavora",
    projectDesc:
      "A project of the Public Administration of the Autonomous Region of Aosta Valley to increase employability, while organizing the events linked to the project.",
    tags: ["Ruby", "ROR", "PostgreSQL", "Redis", "Docker"],
    code: "https://github.com/sthaB-kash",
    link: "https://www.vdalavora.it/",
    image: vdalavora,
  },
  {
    id: 2,
    projectName: "Anandi Jewelry",
    projectDesc:
      "An E-commerce application for buy and selling of jewelry items.",
    tags: ["Ruby", "React", "PostgreSQL, Docker, RoR"],
    code: "https://github.com/sthaB-kash",
    link: "https://jewelry-project.web.app/",
    image: jewelryProject,
  },
  {
    id: 3,
    projectName: "Tuteeline",
    projectDesc:
      "A comprehensive online platform to assist students and interns in there career exploration and placement. Dedicated application portal for streamlining the process, College/consultancy/industry practice search engine.",
    tags: ["React", "Python", "Django", "PostgreSQL", "MaterialUI"],
    link: "https://tuteeline.com/",
    code: "https://github.com/sthaB-kash",
    image: tuteeline,
  },
  {
    id: 4,
    projectName: "Variety D.R.M",
    projectDesc:
      "A Digital Rights Management application for managing movie releases. Variety is an e-commerce platform that employs advanced image recognition technology to facilitate the purchase of movies and connect individuals who share an interest in film-related events.",
    tags: ["Ruby", "ROR", "Redis", "Ethereum", "Docker", "PostgreSQL"],
    code: "https://github.com/sthaB-kash",
    link: "https://variety.staging.touchwa.re/",
    image: variety,
  },
  {
    id: 5,
    projectName: "Sports Kendra",
    projectDesc:
      "A web application for keeping track of sports related activities such as football, cricket.",
    tags: ["Ruby", "ROR", "React", "Redux", "MaterialUI", "Docker"],
    code: "https://github.com/sthaB-kash",
    demo: "https://sports-kendara.web.app/",
    image: sportsKendra,
  },
  {
    id: 6,
    projectName: "RStore",
    projectDesc:
      "An E-Commerce application for selling of electronic devices/equipments such as laptops, mobiles, and other accessories.",
    tags: ["RoR", "React", "PostgreSQL", "Redux"],
    code: "https://github.com/sthaB-kash",
    demo: "https://github.com/sthaB-kash",
    image: rstore,
  },
  {
    id: 7,
    projectName: "GeSog",
    projectDesc:
      "Italian Army project developed in microservice architecture for keeping track of army holidays/vacation, printing & shipping army card and hotel reservation.",
    tags: [
      "Spring Boot",
      "Entando",
      "PostgreSQL",
      "RabbitMQ",
      "Docker",
      "Microservices",
      "Java",
    ],
    code: "https://github.com/sthaB-kash",
    demo: "https://github.com/sthaB-kash",
    image: gesog,
  },
  {
    id: 8,
    projectName: "EyeSome",
    projectDesc:
      "This e-commerce platform is designed to provide a convenient and accessible online shopping experience for customers seeking a wide variety of glasses.",
    // "The platform caters to both men and women, offering a curated selection of unisex styles, ensuring there is something for everyone.",
    tags: ["RoR", "React", "Redux", "PostgreSQL"],
    code: "https://github.com/sthaB-kash/eye-some",
    link: "https://eye-some.vercel.app/",
    image: eyeSome,
  },
  {
    id: 9,
    projectName: "Legal Help",
    projectDesc:
      "A product used by lawyers and their clients. It is used to report unexpected inspection visits by users, so that the connected lawyers are promptly notified and can intervene.",
    tags: ["Ruby", "ROR", "PostgreSQL", "Redis", "Docker"],
    code: "https://github.com/sthaB-kash",
    demo: "https://github.com/sthaB-kash",
    image: six,
  },
  {
    id: 10,
    projectName: "InBellezza",
    projectDesc:
      "Hair dresser's shop management application where client can book an appointment individually.",
    tags: ["Ruby", "ROR", "PostgreSQL", "Redis", "Docker"],
    code: "https://github.com/sthaB-kash",
    link: "https://backend.inbellezza.app/",
    image: inbellezza,
  },
  {
    id: 11,
    projectName: "Employee Management",
    projectDesc:
      "This project aims to build an employee management system that consolidates all information of a company",
    tags: ["React", "CSS", "Material Ui"],
    code: "https://github.com/sthaB-kash",
    demo: "https://github.com/sthaB-kash",
    image: one,
  },
  {
    id: 12,
    projectName: "Weather App",
    projectDesc:
      "Weather forecast systems and applications predict weather conditions based on multiple parameters.",
    tags: ["Django", "CSS", "Material Ui"],
    code: "https://github.com/sthaB-kash",
    demo: "https://github.com/sthaB-kash",
    image: three,
  },
  {
    id: 13,
    projectName: "Patient Tracker",
    projectDesc:
      "This project involves the development of an Android application for viewing and managing patient data.",
    tags: ["React", "ROR", "Firebase", "MUI"],
    code: "https://github.com/sthaB-kash",
    demo: "https://github.com/sthaB-kash",
    image: four,
  },
  {
    id: 14,
    projectName: "Railway Tracking",
    projectDesc:
      "This project is a railway tracking system that allows users to obtain relevant information about train timing.",
    tags: ["Django", "Tailwind CSS"],
    code: "https://github.com/sthaB-kash",
    demo: "https://github.com/sthaB-kash",
    image: two,
  },
];
