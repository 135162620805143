export const socialsData = {
    github: 'https://github.com/sthaB-kash',
    facebook: 'https://www.facebook.com/bikash.shrestha.56211497',
    linkedIn: 'https://www.linkedin.com/in/bikash-shrestha-90922b183/',
    instagram: 'https://www.instagram.com/bikash___shrestha',
    codepen: 'https://codepen.io/sthab-kash',
    twitter: 'https://twitter.com/Bikash2073',
    // reddit: 'https://www.reddit.com/user/',
    // blogger: 'https://www.blogger.com/',
    medium: 'https://medium.com/@',
    stackOverflow: 'https://stackoverflow.com/users/18756191/bikash-shrestha',
    gitlab: 'https://gitlab.com/sthaB-kash',
    // youtube: 'https://youtube.com/'
}