export const educationData = [
    // {
    //     id: 4,
    //     institution: 'University of West London',
    //     course: 'Msc. in Software Engineering',
    //     startYear: 'Further',
    //     endYear: 'Study'
    // },
    {
        id: 1,
        institution: 'Kantipur City College',
        course: 'Bachelor in Computer Application',
        startYear: '2017',
        endYear: '2021'
    },
    {
        id: 2,
        institution: 'Saptagandaki Multiple College',
        course: '+2 (Intermediate)',
        startYear: '2014',
        endYear: '2017'
    },
    {
        id: 3,
        institution: 'Saradpur Higher Secondary School',
        course: 'SLC(Secondary level)',
        startYear: '2004',
        endYear: '2014'
    },
]