export const experienceData = [
    {
        id: 1,
        company: 'Kotuko',
        jobtitle: 'Software Engineer',
        startYear: '2022',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'Bajra Technologies',
        jobtitle: 'Associate Software Engineer',
        startYear: '2021',
        endYear: '2022'
    },
    {
        id: 3,
        company: 'Anchor Digital Agency',
        jobtitle: 'React Developer',
        startYear: '2020',
        endYear: '2021'
    },
]